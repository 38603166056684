import { config } from '@/config'

export const schemaMarkup = {
  '@context': 'https://schema.org/',
  '@type': 'Product',
  name: 'RSS.com',
  alternateName: 'RSS․com',
  description:
    'RSS.com is a podcast hosting, distribution, and monetization platform designed for podcasters of all experience levels. RSS.com makes it simple to start, track, and grow a podcast.',
  url: config.baseUrl,
  image: {
    '@type': 'ImageObject',
    url: config.baseUrl + '/icon@2x.png',
    width: 512,
    height: 512
  },
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: '4.81',
    bestRating: '5',
    worstRating: '1',
    ratingCount: '75'
  },
  review: [
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'u/famousashley'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reddit',
        sameAs: 'https://www.reddit.com'
      },
      reviewBody:
        "I love RSS.com. I've used them since starting my podcast 4 years ago. I agree 1,000% that they have fair pricing. Out of all 11 options I was considering they offered everything I was looking for. My favorite thing about them is they don't nickel and dime you for every feature and they are constantly rolling out new upgrades to their software."
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'u/Temporary-Gap-2556'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reddit',
        sameAs: 'https://www.reddit.com'
      },
      reviewBody:
        'I am using rss.com and im very happy using it. It doesn’t have limit on audio hours or episodes which is great because I upload more than 3 hours of audio each month. And it has very nice features with advanced analytics.'
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'u/zerofoxtrot93'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reddit',
        sameAs: 'https://www.reddit.com'
      },
      reviewBody: "RSS.com is solid, been using it for a year. Switched from Podbean, wouldn't go back."
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'u/denethar'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reddit',
        sameAs: 'https://www.reddit.com'
      },
      reviewBody: 'RSS.com is great. I love it!\n\nThe analytics are amazing. Very helpful and informative to growing a podcast.'
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'u/aemarques, Música & Som Podcast'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reddit',
        sameAs: 'https://www.reddit.com'
      },
      reviewBody:
        "Back in 2022, I looked around to host my podcast  and ended up choosing RSS.com due to low price, ease of use and also ease of distribution.\n\nThey recently made it also possible to distribute through Youtube, but I'm doing this separately. However, everything else is managed by RSS.com.\n\nOnly issue (but not too serious for me) is that the Analytics page is not updated daily and lags behind the true reach. Other than that, 5 stars."
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Saeed Ahmed Agha'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'I recently started my podcasting journey, and after researching several hosting platforms, I decided to go with RSS.com—and I couldn’t be happier with my choice! From the very beginning, the platform has been incredibly user-friendly and intuitive, making it easy for a beginner like me to get started. Setting up my podcast was a breeze. The step-by-step guidance helped me create my RSS feed, submit my show to major directories like Apple Podcasts and Spotify, and even design a professional-looking podcast website. The analytics dashboard is also super helpful, giving me clear insights into my audience and episode performance. What really stands out about RSS.com is their customer support. Whenever I had a question, their team was quick to respond and incredibly helpful. Plus, the pricing is transparent and affordable, especially for the value you get. If you’re looking for a reliable, easy-to-use podcast hosting platform that grows with you, I highly recommend RSS.com. It’s been a game-changer for my podcasting journey!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Filip Volf'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'Everything, great UX great UI and great service and fast reliable.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'David Bowers'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        "I was surprised to FIND this, just as I was NEEDING a new publishing platform, since my old one just quit podcast streaming. And, to find that it was seemingly easy to use and publish was just frosting on the cake. My tech guy was amazed that I was able to create the site in so little time. A little too early for final 'judgement' but, so far, I am impressed.",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Toni Wise'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'My experience has been great so far because i thought this was going to be hard to do but this app has made it super easy and helpful and the butterflies i once experienced has been gone in the wind thanks rss',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Dr Andre Jerry'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'Just makes it SO EASY to get your podcast up-and-running without overcomplicated steps. Highly recommend for those new to podcasting!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Kristene Gulla'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'The videos offer a ton of useful information, especially for someone brand new to podcasting. Thank you!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Sally Ann Jackson'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        "**Superlatively user friendly and helpful**\nI am a complete novice at anything computer related. I don't understand the terminology, the processes, basically how to do anything except type.\n\nI can't quite believe it, and haven't any proof yet, but I think I succeeded in doing an audio recording on my laptop, and then through the RSS website have uploaded it and published my first episode. if I have succeeded, it is entirely down to the very logical, very well explained, very well sequenced instructions on the website. Even if I haven't succeeded, I feel very happy instead of experiencing my usual frustration at my failure to do anything except write! I feel like I can keep going! Congratulations thus far RSS!",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Alli Wilber'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        "I was always nervous about starting a podcast because I didn't know where to begin when it came to publishing an episode. RSS has made it so easy I wish I would have started sooner!",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Telling Tinder Tales'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: "This is a fantastic service for podcasters. It's very user friendly and we're super happy with this platform.",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Minister Andrea Johnson'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'You must try this it was fast and easy to start my own podcast even with my visual disability I was able to see everything on the website without the aid that I usually use wow typing on the computer. This company makes it easy for someone without professional skills to start up their own podcast.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Broad St South'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'The people & team at RSS are always trying to be ahead of other platforms. The customer service is outstanding! They find ways to make it easier for your shows to be noticed by the public. I have been with the company for 5 years & they have always had second to none service for my company.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Ying Zhou'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'rss.com platform is easy to use for beginners. Upload and publish the podcast takes a couple of munites. As soon as the episode is published, its url is ready for sharing and the corresponding webiste is live. It is an enjoyable experience.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'John Alexander'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        "I have used RSS before and when I decided to start another podcast I researched and thought I would try one of the more 'popular' hosts....bad idea, RSS is so much easier and is more thoroughly complete especially in the automatic distribution area. The other hosts I tried, well, there was way more you have to do manually to include having to reconfigure your audio file to another format that is just time consuming and very aggravating. RSS is a breeze and just the easiest of all...and I've tried 3 of the \"top' popular hosts. Not to mention everything is unlimited here where as the other hosts are higher priced and limited at what you can do and store....not RSS!!",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Devon & Jonathan Grey'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'I had tried a couple of services before and RSS.com has one of the best values for a very competitive price. The interface was also extremely user/beginner friendly and made getting started a breeze.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Jiaxin Li'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'I totally love my experience of publishing my first podcast! It was simple and straightforward and it allows me to edit unlimited times before going live. I like that it allows me to add transcript automatically or manually, as well as splitting chapters if needed.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Leona'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'Easy to use, quick, and fast to upload',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Nick Materese'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: "So easy! Why didn't I do this earlier?!???\n\nGreat experience and I am now going to publish way more episodes!!!!",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Broad St South'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'I have been with RSS for the last 3 years and it is such an easy platform to navigate and make your audio as visible as possible. My company has grown with listeners from around the world because of the level of professionalism rss.com provides the consumer with levels of success. Everyone should subscribe to rss.com',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Zinhle Bhembe'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'very easy and user friendly for beginners. so professional. There are no catches. I absolutely love it. I am glad I chose this over the other podcast launching platforms. I love the tutorials and guides on how to start and make your podcast successful',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Anna Martin'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'I started this on a whim so my clients could listen to me rather than have to sit down and watch a video and it was so easy - thank you! 30 min podcast recorded, uploaded and finished within an hour. Great!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Michelle Langthorne'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        "So easy to use and follow. I am a professional in media production but going it alone for the first time so it's comforting to know that RSS have really got my back in terms of getting my content out there.",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Mary Barbara Hanna'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'Using RSS has been the easiest part of creating this podcast! Instructions are clear, the interface clean, and results immediate. Thank you, RSS!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Puru Thapa'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'I am so happy. I just created my first podcast on RSS within less than 5 minutes. However, I have yet to learn more. I just tried first time.\n\nThank you RSS.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer (no name given)'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        "It was a breeze to publish my first episode. Rarely is it simple to take an idea and turn it into a tangible product, but in this case it was as easy as 3.14! My podcast, NI vs. AI, is now out there. How exciting! Thanks, RSS.com! I'm looking forward to exploring the other features more fully.",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Alberto Rocha'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'It was so easy!!!! I was impressed with the reviews about RSS.com platform and so I made the move to explore. I am very happy how smooth things are going!!!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Dïänä Jin'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'I have been using RSS.com to host my podcast and it has been an excellent experience so far. The platform is user-friendly and offers all the essential tools needed to upload and manage episodes seamlessly. The analytics feature is particularly helpful in understanding my audience better. Additionally, the support team is responsive and always ready to assist with any queries. I highly recommend RSS.com to both new and experienced podcasters looking for a reliable hosting service.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Heavenly Minded Academy'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'A fantastic way for beginners to start a podcast!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Onshalique Wilson'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'The ease of editing in screen or on phone are awesome and the price point for paid is fair!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'John'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        "Been struggling with other platforms but they don't give half as much as RSS.com.\n\nEasy to use and publishing is simple. Looking forward to publishing a lot more very soon.",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Delilah Beau'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'Everything is so simple, and easy to use, reasonably priced, and perfect for creating and hosting your RSS! Would highly recommend!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Patrick'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'I´ve two German Podcasts hosted via RSS.com and it´s simple and easy to use.\n\nNo complaints from my side.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'tinns'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: "I'm completely new to content creation in general, let alone podcasting. RSS has made this as easy as 1-2-3.",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Terry Jarrell'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        "I just finished uploading my first podcast and RSS.com has made it so easy for me to get started! After spending so many hours the past two weeks, I found everything I needed right here in laymen's terms to get started fast! WOW! Love using RSS.com already!",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Maria Abdel Rahman'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'Easy, great, fun.\n\nGetting your thoughts out into the world - was easy. Instead of collecting dust in your voice memo’s app. Give it a chance to breathe life by allowing the world to see!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Bantaboyz Z&L'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'I have been going thru websites all day until I found this one that let me make my podcast with ease',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Maritza'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        "This was such an easy process, for months I've been overthinking it and finally, I got it done. From start to finish it didn't take me more than 15 minutes.",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Dr. Reginald Woods'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'The process was easy and I am looking forward to people benefiting from the episodes. Thanks!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Billy Burke'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'Support! Ana...phenomenal!!! Walked me through the process after I made a mistake. Awesome! Easy to do!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Noel Hoines'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'I tried getting started with YouTube and Spotify but RSS.com is way easier for beginners.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Cantona Joseph'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'The experience is great taking Radio Fortune Africa to the next level, the experience its real blessings.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'ilias ro'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody:
        'Creating and publishing a podcast has never been easier, they provide everything you need with no effort and in five minutes your podcast is available on the internet , and most important you have a month free, by far is the best service I have received so far.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: "Taveau D'Arcy"
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: "Sure quality plus ease. It's simple. Fine graphics. Well explained. HIGH QUALITY. EASE OF USE × IS HELPFUL",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Susan Post'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'Thank you so much for all your help and support.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'City Grant'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: "The simplicity alone gives an ease of access that's unparalleled to other platforms.",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'D Renee Neal'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: "It was super easy. I didn't need my kids to guide me through, which is saying a lot.",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Angie Engstrom'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'Made it so simple to publish my first podcast episode. Thank you for keeping the tech simple.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Paige Retchko'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'Very easy to use; straight forward and easy to understand instructions.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Joel Fortune'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'Really easy and straight forward to use. Couldnt have asked for an easier set up',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Andrew George'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'RSS is awesome! Easy, straightforward, 10/10 service.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Jack Cunningham'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Trustpilot',
        sameAs: 'https://www.trustpilot.com'
      },
      reviewBody: 'This website made it really easy to get started; thank you!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody: 'Reasonable pricing and easy navigation, what else can you ask for?',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody:
        'I set up a profile and everything for my podcast, after just hearing about this program today. It has been the most seamless part of this process.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody:
        'First time every doing a podcast for a school assignment and RSS just made it super easy and simple to do, spent at most five minutes from making my account to publishing my podcast, super nice and simply for first timers.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody:
        'RSS.com is an excellent platform for starting and managing a podcast. Its user-friendly interface makes it easy to upload episodes, track analytics, and distribute content across major platforms. The pricing is affordable, and the customer support is top-notch. For podcasters looking to share content seamlessly, RSS.com is a fantastic choice. Check out https://menupricesdutchbros.com/ for inspiration on creating niche-focused content!”',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody:
        'RSS.com is a user-friendly podcast hosting platform that offers unlimited audio storage, straightforward episode management, and automatic distribution to major directories like Apple Podcasts and Spotify. It also features detailed analytics and multiple monetization options, making it a great choice for both new and experienced podcasters looking for comprehensive podcasting solutions.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody:
        "I was genuinely impressed with my first experience using RSS.com to kickstart my podcasting journey. The platform’s intuitive and user-friendly interface made the entire process smooth and hassle-free, even for a complete beginner like me.\n\nOne major highlight is the automatic distribution to major platforms like Spotify, Apple Podcasts, and Google Podcasts, which saved me so much time and effort. The analytics tools are also fantastic—clear, detailed, and incredibly helpful for tracking the performance and growth of my podcast.\n\nI also love the affordable pricing and the unlimited features, such as storage and the number of episodes. It’s the perfect choice for anyone who wants to focus on creating great content without worrying about technical limitations.\n\nIf you're looking for a professional, easy-to-use, and reliable podcasting platform, I’m confident that RSS.com will exceed your expectations.",
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody: 'Super Easy Process, with great videos on YouTube to guide me. 10/10 Would Recommend',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody:
        'I am so grateful to RSS.com. This is the very first time I have ever made a podcast, and RSS.com made it so easy.  Thank you so much, you kindly people!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody: 'This is easy for beginners and fast to load. Impressed with the platform so far. Looking forward to the future.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody: 'Very easy and intuitive. It is so simple I was able to do it.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody:
        'Good place to get a podcast up and running. Very easy to use. It holds your hand all the way through. Even a monkey could publish a podcast!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody: 'Quick and extremely user friendly with step by step guidance from the amazing team.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody: 'Great Interface, very user friendly. What seemed very confusing on other platforms was very easy and encouraging here.',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody:
        'Great solution that helps save time and gets the word out about our podcast!\n\n– Included a picture of her podcast art – Wellbeing ignites Welldoing With Adwoa K. Buahene',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody:
        'Once Spotify for Podcasters completely integrated with their new hosting platform EVERYTHING changed — the once ergonomically fluid recording and editing feature was nowhere to be found (and if you weren’t careful you lost all of your podcast episodes…*cough* me *cough*). RSS is by far a lifeline to continuing my podcast. I tried three other hosting platforms and none were as fluid or as fast. RSS is an amazing option for everyone THANK YOU RSS!!!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody:
        'RSS.com is fantastic! It made launching our podcast, "Dakdekker Amersfoort," super easy. Great support and excellent analytics. Highly recommend!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Reviews.io',
        sameAs: 'https://www.reviews.io'
      },
      reviewBody:
        'This is my first time ever coming here and the experience has been absolutely amazing because it’s so easy! I am a nervous podcaster that’s doing this on my own right now so the access and information is VERY helpful! Can’t wait to see where and how this goes. Thank you!',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '4.5',
        bestRating: '5',
        worstRating: '1'
      }
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Mike Jonjak'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Facebook',
        sameAs: 'https://www.facebook.com'
      },
      reviewBody: 'easy to set up and get started'
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: 'Jamie Birch'
      },
      publisher: {
        '@type': 'Organization',
        name: 'Facebook',
        sameAs: 'https://www.facebook.com'
      },
      reviewBody:
        "I love this service!  I had been wanting to start a podcast for the affiliate marketing industry for some time.  I was holding back because I didn't know how I would host and distribute it.  But then we started working with RSS and it was so easy.  Took me more time to write the description of my podcast than to get it all set up!  It was so easy that I wasn't sure I did it all correctly, but I did!  I love it and it makes things very easy to get started.  I highly recommend it."
    }
  ]
}
